import React from "react"
import { graphql } from "gatsby"
import LayoutV2 from "../components/v2/layout-no-padding-site-main"
import SEO from "../components/seo"
import rehypeReact from "rehype-react"

import "../utils/css/components/job.css"
import { PLink, BlogListItem, EmptyComponent, BlogList, Section } from "../components/partials/base";

import { IoIosBook } from "react-icons/io"
import { MdLocationCity } from "react-icons/md"

import {Button, LinkButton} from "../components/partials/widgets"

class JobPostTemplate extends React.Component {
    constructor(props) {
      super(props)
      this.parChild = React.createRef()
      this.parChildDetails = React.createRef()
    }

    render() {
      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
            "title-section": TitleSection,
            "img" : ImageSection
        },
      }).Compiler
  
      const post = this.props.data.markdownRemark
      const siteTitle = this.props.data.site.siteMetadata.title

      return (
        <LayoutV2
          location={this.props.location}
          title={siteTitle}
          headerActive={`Insights`}
        >
          <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
          />
          <div className="job-page">
            <TitleSection frontmatter={post.frontmatter}/>
            <ApplyForThisJob href={post.frontmatter.joblink}/>
            <article className="job-description-div animated fadeIn">
                {renderAst(post.htmlAst)}
            </article>
            <ApplyForThisJob href={post.frontmatter.joblink}/>
          </div>
        </LayoutV2>
      )
    }
}

class ApplyForThisJob extends React.Component {
    constructor(props){
        super(props);
    }

    render () {
        const {href} = this.props;
        return <div className={`job-page-apply-div`}>
            <a className={`p-link p-link--btn`} href={href} target={`_blank`}>APPLY FOR THIS JOB</a>
        </div>
    }
}

class ImageSection extends React.Component {
    render () {
        const { src } = this.props; 
        return <p style={{textAlign: 'center'}}><img src={src} className="job-img"/></p>
    }
}

class TitleSection extends React.Component {
    render() {  
        const {title, location, type} = this.props.frontmatter;
        return (
            <div className="job-title-div">
                <div className="job-title-div--title">
                    {title}
                </div>
                <div className="job-title-div--desc">
                    <span className="job-title-div--desc-location">
                        {location}
                    </span>
                     • 
                    <span className="job-title-div--desc-type">
                        {type}
                    </span>
                </div>
            </div>
        )
    }
}  
export default JobPostTemplate

export const pageQuery = graphql`
  query JobBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        location
        type
        joblink
      }
    }
  }
`
